.BagUsagePage {
    h3 {
        text-align: left;
        font-size: 20px;
        font-weight: bold;
    }
    .input-section {
        margin-bottom: 30px;
        h3 {
            margin-bottom: 15px;
        }
        .ant-form {
            background: #e2f2f0;
            padding: 30px 0px;
            .ant-form-item {
                vertical-align: bottom;
                .ant-select {
                    width: 180px;
                }
                .ant-input {
                    width: 180px;
                }
                .bw-btn {
                    padding: 0px 60px;
                    font-size: 18px;
                    background: #48b0c2;
                }
            }
        }
    }
    .table-section {
        .title-section {
            display: flex;
            h3 {
                flex: 1;
                text-align: left;
                display: flex;
                align-items: center;
            }
            .FilterGroup {
                flex: 3;
                text-align: right;
            }
        }
        .ant-table {
            .icon-btn {
                cursor: pointer;
            }
            .check-btn {
                opacity: 0.2;
                transition: all .3s;
                &:hover {
                    opacity: 1;
                    color: #090;
                }
                &.checked {
                    opacity: 1;
                    cursor: auto;
                    color: #090;
                }
            }
            .remove-btn {
                color: #f16536;
            }
        }
    }
}