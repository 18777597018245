/* open-sans-regular - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: url('./fonts/OpenSans/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
	src: local('Open Sans Regular'), local('OpenSans-Regular'),
		url('./fonts/OpenSans/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('./fonts/OpenSans/open-sans-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		url('./fonts/OpenSans/open-sans-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
		url('./fonts/OpenSans/open-sans-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		url('./fonts/OpenSans/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

html, body {
	min-width: 720px;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button {
	outline: 0;
	border: 0;
	cursor: pointer;
}

*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background-color: #f7f7f7;
}

*::-webkit-scrollbar-thumb {
    background-color: #00aec1;
    outline: 1px solid #00aec1;
}

.container {
	width: 100%;
	max-width: 1260px;
	margin: 0 auto;
}

.ant-modal-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-modal {
	top: 0;
    color: #1c3d74;
    padding-bottom: 0;
    .ant-modal-content {
        background: url('./modal-bg.png') right bottom no-repeat;
        background-size: auto;
        background-color: #e2f2f0;
        border-radius: 30px;
        .ant-modal-body {
            padding: 60px 60px;
            text-align: center;
            font-size: 36px;
            button {
                color: #fff;
                font-size: 24px;
                padding: 5px 100px;
                background: #00aec1;
                border-radius: 500px;
                transition: opacity .3s;
                &:hover {
                    opacity: 0.7;
                }
                &:active {
                    opacity: 1;
                }
            }
            .ant-form {
	            .ant-form-item-control {
	            	text-align: left;
	            }
	            .ant-select {
	            	.ant-select-selection {
	                	border-radius: 30px;
	            	}
	            }
	            .ant-input,
	            .ant-input-number {
	                .ant-input-number-handler-wrap {
	                    border-radius: 0 30px 30px 0;
	                }
	                .ant-input-number-handler-active {
	                    &.ant-input-number-handler-up {
	                        border-radius: 0 30px 0 0;
	                    }
	                    &.ant-input-number-handler-down {
	                        border-radius: 0 0 30px 0;
	                    }
	                }
	            }
            }
        }
    }
}

.ant-table {
    .ant-table-thead {
        tr {
            th {
                background: #1c3d74;
        		color: #fff;
            }
        }
    }
    .ant-table-footer {
        background: #1c3d74;
        color: #fff;
    }
	.ant-table-tbody {
		td {
			background-color: #fff;
			vertical-align: middle;
		}
		.ant-table-column-sort {
			background: #fff;
		}
	}
}

.ant-tooltip {
	max-width: 350px;
}

.bw-btn {
    font-size: 1.2em;
    background: #1c3d73;
    border-radius: 15px;
    color: #fff;
    transition: opacity .3s, background .3s;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
    &:active {
        opacity: 1;
    }
}

@media screen and (max-width: 720px) {
	.ant-table-thead > tr > th, 
	.ant-table-tbody > tr > td {
		padding: 10px;
	}
}