.LoadingScreen {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    .ant-spin-dot {
        margin: auto;
    }
}

@media screen and (min-height: 1440px) {
	.LoadingScreen {
		font-size: 36px;
	}
}