.AdminPage {
    width: 100%;
    text-align: center;
    padding: 15px 0px;
    .btn-menu {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 10px;
        .back-btn {
            margin-right: auto;
            padding: 10px 20px;
            box-shadow: 0 5px 0 #081935, 0 5px 3px rgba(0, 0, 0, .4);
            .anticon {
                margin-right: 10px;
            }
        }
        .logout-btn {
            background-color: #e3eda4;
            border-color: #e3eda4;
            color: #1c3d74;
            box-shadow: 0 5px 0 #c9dc4f, 0 5px 3px rgba(0, 0, 0, .4);
            margin-bottom: 5px;
            padding: 13px 20px 10px 20px;
            font-size: 1.2em;
            border-radius: 1000px;
            transition: all .3s;
            cursor: pointer;
            &:hover {
                background-color: #ecf3c2;
                border-color: #ecf3c2;
                opacity: .9;
            }
        }
    }
    .ant-table {
        .ant-table-thead {
            tr {
                th {
                    background: #e2f2f0;
                    &.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
                        background: #e2f2f0;
                    }
                }
            }
        }
        .ant-table-footer {
            background: #e2f2f0;
        }
    }
}