.WasteUsagePage {
    h3 {
        text-align: left;
        font-size: 20px;
        font-weight: bold;
    }
    .input-section {
        margin-bottom: 30px;
        h3 {
            margin-bottom: 15px;
        }
        .ant-form {
            background: #e2f2f0;
            padding: 10px 15px;
            .ant-form-item {
                margin-bottom: 10px;
                .ant-select {
                    width: 180px;
                }
                .ant-input {
                    width: 180px;
                }
                .bw-btn {
                    padding: 0px 60px;
                    font-size: 18px;
                    background: #48b0c2;
                }
                .ant-form-item-label,
                .ant-form-item-control {
                    text-align: left;
                }
                .unit {
                    margin-left: 5px;
                }
            }
            .form-submit-btn {
                .ant-form-item-control {
                    text-align: center;
                }
            }
        }
    }
    .table-section {
        .ant-tabs-bar {
            margin-bottom: 0;
        }
        .title-section {
            display: flex;
            h3 {
                flex: 2;
                text-align: left;
                display: flex;
                align-items: center;
            }
            .FilterGroup {
                flex: 3;
                text-align: right;
            }
        }
        .ant-table {
            .icon-btn {
                cursor: pointer;
            }
            .remove-btn {
                color: #f16536;
            }
        }
    }
}