.FilterGroup {
    .filter-group-filter {
        margin: 10px 7.5px;
    	&:first-child{
    		margin-left: 0;
    	}
    }
    .ant-calendar-picker {
        width: 200px;
    }
}