.WebInterface {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    .container {
        display: flex;
        justify-content: center;
        flex: 1 0 auto;
        padding: 0px 15px;
        &.stretched {
            max-width: unset;
            padding: 0;
        }
    }
}