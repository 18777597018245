.EditHelperSchedulePage {
    h3 {
        text-align: left;
        font-size: 20px;
        font-weight: bold;
    }
    .contact-section {
        margin: 15px 0;
        p {
            display: inline-block;
            text-align: left;
            width: 44%;
            font-weight: bold;
        }
        .ant-input {
            margin-left: 10px;
            width: 250px;
        }
        .bw-btn {
            width: 12%;
            font-size: 18px;
            background: #48b0c2;
            line-height: 32px;
        }
    }
    .input-section {
        margin-bottom: 30px;
        .ant-form {
            background: #e2f2f0;
            padding: 30px 0px;
            .ant-form-item {
                vertical-align: bottom;
                .ant-select {
                    width: 180px;
                }
                .ant-input {
                    width: 180px;
                }
                .bw-btn {
                    padding: 0px 60px;
                    font-size: 18px;
                    background: #48b0c2;
                }
            }
        }
    }
    .table-section {
        .title-section {
            display: flex;
            h3 {
                flex: 1;
                text-align: left;
                display: flex;
                align-items: center;
            }
            .FilterGroup {
                flex: 3;
                text-align: right;
            }
        }
        .ant-table {
            .icon-btn {
                cursor: pointer;
            }
            .remove-btn {
                color: #f16536;
            }
        }
    }
}