.StudentHelperPanelPage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    .lower-section {
        width: 100%;
        height: 100%;
        .ant-tabs {
            height: 100%;
            .ant-tabs-ink-bar {
                width: 5px;
                background-color: #fff;
            }
            .ant-tabs-bar {
                position: relative;
                margin: 0;
                background: #1c3d74;
                .ant-tabs-nav-container {
                    color: #fff;
                    text-align: center;
                    padding: 15px 0;
                    height: auto;
                    .ant-tabs-tab {
                        color: #fff;
                        font-size: 1.5em;
                        text-align: center;
                        padding: 8px 60px;
                        &.ant-tabs-tab-active {
                            font-weight: bold;
                        }
                    }
                    .ant-tabs-ink-bar {
                        height: 5px;
                    }
                    .ant-tabs-nav-wrap {
                        height: auto;
                    }
                }
                .ant-tabs-extra-content {
                    width: 100%;
                }
            }
            .ant-tabs-content {
                padding: 0 10px;
            }
        }
        .ant-table {
            .icon-btn {
                cursor: pointer;
            }
            .remove-btn {
                color: #f16536;
            }
        }
        .ant-table-footer {
            .table-footer {
                font-weight: bold;
                text-align: center;
            }
        }
        .profile-section {
            text-align: center;
            margin-top: 15px;
            .profile-pic {
                background: #fff;
                border-radius: 36px;
                width: 200px;
            }
            p {
                color: #fff;
                font-size: 18px;
                line-height: 24px;
            }
        }
        .bag-store-section {
            flex: 7;
            text-align: center;
            .store-title {
                color: #1c3d73;
                font-size: 24px;
                font-weight: bold;
                padding: 5px 0;
            }
            .table-footer {
                font-size: 24px;
                color: #999;
            }
            .store-interface {
                position: relative;
                padding: 30px 0px 0px 0px;
                .store-ad {
                    position: absolute;
                    height: 110px;
                    left: -30px;
                    bottom: -15px;
                }
                .store-header {
                    position: absolute;
                    top: 0;
                    background: url('./store-header.svg') bottom center repeat-x;
                    height: 50px;
                    width: 100%;
                }
                form {
                    .form-item {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 5px;
                        padding: 10px 30px;
                        font-size: 18px;
                        .form-label {
                            // display: inline-block;
                            // width: 20%;
                            flex: 2;
                            text-align: left;
                            vertical-align: middle;
                        }
                        .form-input {
                            // display: inline-block;
                            // width: 80%;
                            flex: 8;
                            text-align: left;
                            .bags-container {
                                display: flex;
                                align-items: center;
                            }
                        }
                        .bw-btn {
                            background: #1c3d73;
                            padding: 10px 100px;
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .StudentHelperPanelPage {
        padding: 0;
        .upper-section {
            flex-direction: column;
            margin-bottom: 10px;
            .profile-section {
                margin-bottom: 15px;
                .profile-pic {
                    width: 130px;
                }
                p {
                    font-size: 16px;
                }
            }
            .bag-store-section {
                width: 90%;
                .store-title {
                    font-size: 18px;
                }
                .store-interface {
                    padding: 40px 0px 0px 0px;
                    border-bottom: 10px solid #f05a28;
                    .store-header {
                        height: 40px;
                    }
                    form {
                        .form-item {
                            font-size: 16px;
                            margin-bottom: 0;
                            padding: 5px 30px;
                            .bw-btn {
                                background: #1c3d73;
                                padding: 10px 80px;
                                font-size: 18px;
                            }
                        }
                    }
                    .store-ad {
                        height: 90px;
                        left: -28px;
                        bottom: -10px;
                    }
                }
            }
        }
        .lower-section {
            .ant-tabs-bar {
                .ant-tabs-nav-container {
                    .ant-tabs-tab {
                        font-size: 1.2em;
                    }
                }
            }
            .ant-table-footer {
                padding: 10px;
            }
        }

    }
}