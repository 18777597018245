.SelectActionPage {
	.action-options-gp {
		.action-option {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			height: 160px;
			width: 20%;
			min-width: 250px;
			background: #d6de23;
			margin: 15px;
			font-size: 18px;
			line-height: 30px;
			padding: 15px;
			vertical-align: middle;
			color: #000;
		}
	}
}