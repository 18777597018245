.SelectSchoolPage {
	.instruction {
		font-size: 24px;
	}
	.school-options-gp {
		.school-option {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			height: 180px;
			width: 40%;
			max-width: 400px;
			background: #00aec1;
			margin: 15px;
			font-size: 24px;
			line-height: 36px;
			padding: 15px;
			vertical-align: middle;
			color: #000;
		}
	}
}