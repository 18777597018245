.ApproveStudentDesignPage {
    h3 {
        text-align: left;
        font-size: 20px;
        font-weight: bold;
    }
    .table-section {
        .title-section {
            display: flex;
            h3 {
                flex: 1;
                text-align: left;
                display: flex;
                align-items: center;
            }
            .FilterGroup {
                flex: 3;
                text-align: right;
            }
        }
        .ant-table {
            .icon-btn {
                cursor: pointer;
            }
            .check-btn {
                opacity: 0.2;
                transition: all .3s;
                &:hover {
                    opacity: 1;
                    color: #090;
                }
                &.checked {
                    opacity: 1;
                    cursor: auto;
                    color: #090;
                }
            }
            .remove-btn {
                color: #f16536;
            }
        }
    }
}