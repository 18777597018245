.RankingTable {
    width: 100%;
    .ant-table-thead {
        tr {
            th {
                background: #d6de23;
                vertical-align: middle;
            }
        }
        .green-label:before {
            display: inline-block;
            content: "";
            background: #007767;
            height: 10px;
            width: 10px;
            vertical-align: middle;
            margin-right: 5px;
        }
        .red-label:before {
            display: inline-block;
            content: "";
            background: #f47920;
            height: 10px;
            width: 10px;
            vertical-align: middle;
            margin-right: 5px;
        }
    }
    .class-profile {
        p {
            text-align: center;
            font-weight: bold;
        }
        .propic {
            width: 100%;
            height: auto;
        }
    }
}