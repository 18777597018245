.ButtonWithIcon {
    cursor: pointer;
    .ButtonWithIcon-icon {
        text-align: center;
        height: 150px;
    }
    .ButtonWithIcon-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 36px;
        background: #14adbf;
        border-radius: 15px;
        padding: 20px 15px;
        color: #fff;
        @media screen and (max-width: 720px) {
            font-size: 26px;
        }
    }
}