.CartoonModal {
    .ant-modal-content {
        .ant-modal-body {
            button {
                margin-top: 60px;
            }
            p {
                padding: 0px 100px;
            }
        }
    }
}