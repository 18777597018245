.DividedBar {
    height: 33px;
    min-width: 10px;
    cursor: pointer;
    .left-bar {
        white-space: nowrap;
        display: inline-block;
        height: 33px;
        min-width: 5px;
        max-width: calc(100% - 5px);
        background: #007767;
        text-align: center;
        line-height: 33px;
        color: #fff;
    }
    .right-bar {
        white-space: nowrap;
        display: inline-block;
        height: 33px;
        min-width: 5px;
        max-width: calc(100% - 5px);
        background: #f47920;
        text-align: center;
        line-height: 33px;
        color: #fff;
    }
}

.DividedBarTooltipContent {
    .tooltip-row {
        .tooltip-green:before {
            display: inline-block;
            content: "";
            background: #007767;
            height: 10px;
            width: 10px;
            vertical-align: middle;
            margin-right: 5px;
        }
        .tooltip-red:before {
            display: inline-block;
            content: "";
            background: #f47920;
            height: 10px;
            width: 10px;
            vertical-align: middle;
            margin-right: 5px;
        }
        .tooltip-label {
            width: calc(75% - 7.5px);
            display: inline-block;
            text-align: left;
            vertical-align: middle;
        }
        .tooltip-value {
            width: calc(25% - 7.5px);
            display: inline-block;
            text-align: right;
            vertical-align: middle;
        }
    }
}