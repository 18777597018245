.SchoolDataPage {
    .table-section {
        h3 {
            text-align: left;
            font-size: 20px;
            font-weight: bold;
        }
        .ant-table {
            .icon-btn {
                cursor: pointer;
            }
            .checked {
                cursor: auto;
                color: #0f0;
            }
            .remove-btn {
                color: #f16536;
            }
        }
    }
}