.DashboardPage {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
    justify-content: space-between;
	.container {
		flex: 97;
		display: flex;
		justify-content: center;
        padding: 0px 15px;
	}
}