.FullWidthCartoonButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 36px;
    background: #d6de23;
    border-radius: 30px;
    padding: 20px 15px;
    color: #1a3d74;
    transition: background .3s;
    img {
        height: 60px;
        &:first-of-type {
            margin-right: 30px;
        }
        &:last-of-type {
            margin-left: 15px;
        }
    }
    &:hover {
        background: #dee54f;
    }
    &:active {
        background: #c2c91e;
    }
}

@media screen and (max-width: 720px) {
    .FullWidthCartoonButton {
        font-size: 26px;
        padding: 15px;
        img {
            height: 50px;
        }

    }
}