.RankChangeIndicator {
	.anticon {
		margin-right: 5px;
	}
	&.up {
		color: #00aec1;
	}
	&.down {
		color: #f16536;
	}
}