.GamingInterface {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .question-box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        background: #e2f2f0;
        border: 5px solid #1c3d74;
        border-radius: 30px;
        text-align: center;
        padding: 10px 60px;
        font-size: 1.4em;
        height: 220px;
        line-height: 1.2;
        .question-tips {
            margin-top: 10px;
            color: #666;
        }
        .answer-explanation {
        	margin-top: 10px;
        	font-size: 0.8em;
        	color: #f16536;
        	text-align: left;
        }
    }
    .answers-section {
        width: 100%;
    }
    .submit-section {
        width: 100%;
        margin-top: 20px;
        text-align: right;
        opacity: 1;
        &.disabled {
            opacity: 0;
            .submit-btn {
                pointer-events: none;
            }
        }
        .submit-btn {
            height: 70px;
            line-height: 70px;
            padding: 0px 60px;
            border-radius: 30px;
            background: #00aec1;
            color: #1c3d74;
            &.submitted {
                background: #d6de23;
            }
        }
    }
}

@media screen and (min-height: 1440px) {
    .GamingInterface {
        font-size: 24px;
        .question-box {
            height: 400px;
            line-height: 1.2;
        }
        .submit-section {
            text-align: center;
            .submit-btn {
                width: 500px;
            }
        }
    }
}