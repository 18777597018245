.EndGameInterface {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	img {
		height: 400px;
	}
	p {
		font-size: 24px;
		margin: 15px 0px 30px 0px;
	}
	.btn-gp {
		button {
			padding: 30px 80px;
			margin: 0px 15px;
			&.logout-btn {
				background: #e2f2f0;
				color: #1c3d74;
			}
			&.play-again-btn {
				background: #00aec1;
				color: #1c3d74;
			}
		}
	}
}