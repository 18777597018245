.HelperSchedulePage {
    h3 {
        text-align: left;
        font-size: 20px;
        font-weight: bold;
    }
    .contact-section {
        margin: 15px 0;
        p {
            display: inline-block;
            text-align: left;
            width: 50%;
            font-weight: bold;
        }
    }
    .table-section {
        .title-section {
            display: flex;
            h3 {
                flex: 1;
                text-align: left;
                display: flex;
                align-items: center;
            }
            .FilterGroup {
                flex: 3;
                text-align: right;
            }
        }
        .ant-table {
            .icon-btn {
                cursor: pointer;
            }
            .remove-btn {
                color: #f16536;
            }
        }
    }
}