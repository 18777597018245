.UploadPhotoModal {
    .ant-spin-blur::after {
        display: none;
    }
    .ant-spin-nested-loading>div>.ant-spin {
        max-height: 100%;
        position: fixed;
        z-index: 99999;
        background: rgba(255, 255, 255, 0.4);
    }
}

.ant-modal .ant-modal-content {}